import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import InstructorCard from "./components/InstructorCard";
import Navigation from "./components/Navigation";
import { COLORS, URLS } from "./utils/constants";
import Footer from "./components/Footer";
import ImageBackground from "./components/ImageBackground";
import { BIOS, FEATURES, IMAGES } from "./utils/text";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const HighlightedText = tw.span`bg-darker_theme_primary text-gray-100 px-4 transform -skew-x-12 inline-block`;

export default () => (
  <AnimationRevealPage disabled>
    <Navigation />

    <InstructorCard
        subheading={<Subheading style={{color: COLORS.text}}>We can't change the direction of the wind, but we can adjust your sails to always reach your destination.</Subheading>}
        heading="Our Mission"
        description={BIOS.mission}
        showButton={false}
        imageSrc={IMAGES.our_mission}
    />

    <InstructorCard
        subheading={<Subheading style={{color: COLORS.text}}>Let the pages of our knowledge be the steps towards the pinnacle of your greatness!</Subheading>}
        heading="Our Vision"
        description={BIOS.vision}
        showButton={false}
        imageSrc={IMAGES.our_vision}
        textOnLeft={false}
    />

    {/* <ImageBackground url = {IMAGES.fred.pushup}/> */}

    <InstructorCard
        subheading=""
        heading="Where we operate"
        description={BIOS.operate}
        buttonRounded={false}
        primaryButtonText="Get in touch"
        primaryButtonUrl={URLS.contact}
        imageSrc = {IMAGES.area}
    />

    <InstructorCard
        subheading={<Subheading style={{color: COLORS.text}}>Trainer</Subheading>}
        heading="Frederico Serra"
        description={BIOS.fred}
        showButton={false}
        imageSrc = {IMAGES.fred.profile}
        features={FEATURES.fred}
        borderRadius="10%"
        borderColor={COLORS.dark_primary}
      />

    {/* <ImageBackground url = {IMAGES.keren.split}/> */}

    <InstructorCard
        subheading={<Subheading style={{color: COLORS.text}}>Trainer</Subheading>}
        heading="Keren Zhu"
        description={BIOS.keren}
        showButton={false}
        imageSrc = {IMAGES.keren.profile}
        textOnLeft={false}
        features={FEATURES.keren}
        borderRadius="10%"
        borderColor={COLORS.dark_primary}
    />

  
    <InstructorCard
        subheading={<Subheading style={{color: COLORS.text}}>Love is in the air</Subheading>}
        heading="Marriage"
        description={BIOS.both}
        showButton={false}
        imageSrc={IMAGES.marriage}
        borderRadius="50%"
        borderColor={COLORS.dark_primary}
    />
        
    <Footer />
  </AnimationRevealPage>
);
