export const COLORS = {
    primary: "#b7e0b0",
    dark_primary: "#7DA36A",
    secondary: "#FFFFFF",
    text: "#000000"
}


export const URLS  = {
    home: "/",
    about: "/about/",
    blog: "/blog/",
    testimonials: "/testimonials/",
    pricing: "/pricing/",
    contact: "/contact/",
    terms_of_service: "/terms-of-service/",
    privacy_policy: "/privacy-policy/",
    login: "/login/"
}


export const PAYPAL = {
    client_id: "AX5kruOV3jOOWxk6KHhwhyrnRLhh0nwHW8OLQRfpOH-HoXVCCi1XnXcQVRBh716quH7SX6UcJUB94GP5",
    currency: "GBP",
    style: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '85%',
        width: 500,
        bgcolor: 'background.paper',
        p: 4,
    }
}

export const TOS_DATE = "June 2, 2024";

const BASE_URL = "https://liflofit.onrender.com"
export const EMAIL_URL = `${BASE_URL}/email`