import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import ReviewPreview from "./components/Reviews";

import { TESTIMONIALS } from "./utils/text";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

const HighlightedText = tw.span`bg-darker_theme_primary text-gray-100 px-4 transform -skew-x-12 inline-block`;

export default () => (
  <AnimationRevealPage disabled>
    <Navigation />
    
    <ReviewPreview 
      heading={<>Customers <HighlightedText>Love Us.</HighlightedText></>}
      testimonials={TESTIMONIALS}
    />
    
    <Footer />
  </AnimationRevealPage>
);
