import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Footer from "./components/Footer";
import { SectionHeading } from "components/misc/Headings";
import Navigation from "./components/Navigation";
import { URLS, TOS_DATE } from "./utils/constants";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Terms & Conditions" }) => {
  return (
    <AnimationRevealPage disabled>
      <Container>
        <Navigation />
        <ContentWithPaddingXl>

          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
  
            <Text>
              <p>Last updated: {TOS_DATE}</p>

              <h2>
                General
              </h2>

              <p>
                Welcome to Liflo Fit! These Terms & Conditions govern your use of our website, www.liflofit.com, and any services provided by Liflo Fit, Inc. ("Liflo Fit," "Company," "we," "us," or "our").
              </p>

              <h2>
                User Responsibilities
              </h2>

              <p>
                By using our Services, you agree to:

                <ul>
                  <li>
                    Use the Services under all applicable laws and regulations.
                  </li>

                  <li>
                    Provide accurate, complete, and current information as required for registration.
                  </li>

                  <li>
                    Maintain the confidentiality of your Account and password.
                  </li>

                  <li>
                    Accept responsibility for all activities that occur under your Account.
                  </li>
                </ul>
              </p>


              <h2>
                Services
              </h2>

              <p>
                We offer:

                <ul>
                  <li>
                    Online personal training
                  </li>

                  <li>
                    Group training and 1-on-1 training in parks in East London
                  </li>

                  <li>
                  Group classes for businesses in London, where we train employees at a specific price per class, negotiated with each company
                  </li>
                </ul>
              </p>

              <h2>
                Age Restriction
              </h2>

              <p>
                You must be at least 18 years old to use the Services. Minors may only use the Services with the consent of a parent or legal guardian.
              </p>

              <h2>
                Health and Safety
              </h2>

              <p>
                Before beginning any training program, clients must complete a Physical Activity Readiness Questionnaire (PAR-Q) and sign an informed consent form, confirming they are fit for physical activity and aware of the associated risks.
              </p>

              <h2>
                Payment and Subscription
              </h2>

              <p>
                Certain Services require payment. By subscribing, you authorize us to charge your chosen
              </p>

            </Text>
            
          <HeadingRow>
            <Heading>Terms of Service</Heading>
          </HeadingRow>
          <Text>

            <h2>Acceptance of the Terms</h2>

            <p>
            Welcome to Liflo Fit, a personal training service provided by Liflo Fit, Inc. ("Liflo Fit," "Company," "we," "us," or "our"). These Terms of Service ("Terms") govern your access to and use of our website, www.liflofit.com (the "Site"), as well as any other products or services offered by us, whether through the Site or otherwise (collectively referred to as the "Services").
            </p>
            <p>
            Please read these Terms carefully. By accessing, browsing, or using the Services, or by clicking to accept or agree to the Terms when this option is made available to you, you acknowledge that you have read, understood, and agreed to these Terms. If you do not agree to these Terms, you may not access or use the Services.
            </p>

            <h2>Privacy Policy</h2>

            <p>
            Your use of the Services is also subject to our Privacy Policy, available <a href={URLS.privacy_policy}>here</a>. Please review the Privacy Policy carefully to understand how we collect, use, and disclose information about you.
            </p>


            <h2>
            Changes to the Terms of Service
            </h2>

            <p>
            We reserve the right to update and revise these Terms at any time without prior notice. Any changes to these Terms will be effective immediately upon posting on the Site. Your continued use of the Services following the posting of any changes constitutes acceptance of those changes.
            </p>

            <h2>
            Access to Services; Account Registration
            </h2>

            <p>
            By accessing the Services, you represent and warrant that you are legally capable of entering into binding contracts and that all information you provide during registration is truthful and accurate. You agree to maintain the accuracy of such information and to update it as necessary.
            </p>

            <p>
            To access certain features of the Services, you may be required to register for an account on the Site ("Account"). You are responsible for maintaining the security of your Account and for all activities that occur under your Account. You agree not to share your Account information with anyone else or use another person's Account without permission.
            </p>

            <p>
            We reserve the right to disable any Account, username, or password at any time in our sole discretion for any reason, including if we believe you have violated these Terms.
            </p>

            <h2>
            Payment Processor; Third-Party Service Provider
            </h2>

            <p>
            In connection with your use of the Services, you may be required to provide payment information. We use third-party payment service providers to process payments, and by using the Services, you agree to abide by their terms and conditions.
            </p>

            <h2>
            Subscription Services
            </h2>

            <p>
            Some features of the Services may require payment of a subscription fee. By subscribing to our Services, you authorize Liflo Fit to charge the designated payment method for the applicable fees.
            </p>

            <p>
            You may cancel your subscription at any time, but no refunds will be provided for any fees already paid. Cancelling your subscription will terminate access to certain features of the Services.
            </p>

            <h2>
            Refunds
            </h2>

            <p>
            Refunds are provided at our sole discretion and on a case-by-case basis. We do not guarantee refunds for any reason.
            </p>


            <h2>
            Eligibility; User Restrictions
            </h2>

            <p>
            You must be at least 18 years old to use the Services. Minors may only use the Services with the consent of a parent or legal guardian.
            </p>

            <p>
            You agree to use the Services only for lawful purposes and in compliance with these Terms.
            </p>

            <h2>
            Health and Consent Requirements
            </h2>

            <p>
            Before participating in any training sessions, you must complete a Physical Activity Readiness Questionnaire (PAR-Q) and provide informed consent to verify that you are fit for training and understand the associated risks and benefits.
            </p>

            <h2>
              Intellectual Property
            </h2>

            <p>
              All content and materials available through the Services are owned or licensed by Liflo Fit and are protected by copyright, trademark, and other intellectual property laws. You may not use, reproduce, or distribute any content from the Services without our prior written consent.
            </p>

            <h2>
            Medical Disclaimer
            </h2>

            <p>
            The information provided through the Services is for educational purposes only and is not intended as a substitute for medical advice. You should consult with a physician before starting any fitness program.
            </p>


            <h2>
            Warranty Disclaimers; Limitation of Liability
            </h2>

            <p>
            THE SERVICES ARE PROVIDED "AS IS" AND WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY LAW, LIFLO FIT DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
            </p>

           <p>
           IN NO EVENT SHALL LIFLO FIT BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE USE OF THE SERVICES.
           </p>

          <h2>
          Indemnification
          </h2>

          <p>
          You agree to indemnify and hold Liflo Fit harmless from any claims, damages, liabilities, and expenses arising out of or related to your use of the Services or any breach of these Terms.
          </p>

          <h2>
          Governing Law
          </h2>

          <p>
          These Terms shall be governed by and construed under the laws of England and Wales. Any dispute arising out of or relating to these Terms shall be resolved exclusively in the courts located in London, United Kingdom.
          </p>

          <h2>
          Contact Us
          </h2>

          <p>
          If you have any questions or concerns about these Terms, please <a href={URLS.contact}>contact us</a>.
          </p>

          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
