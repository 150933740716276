import { useEffect } from "react";
import {PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

export default function PayPalButton(props) {

    const ButtonWrapper = ({ type }) => {
        const [{ options }, dispatch] = usePayPalScriptReducer();
    
        useEffect(() => {
            dispatch({
                // type: "resetOptions",
                value: {
                    ...options,
                    intent: "subscription",
                },
            });
        }, [type]);


        const on_approve = (data, actions) => {
            props.setOpen(true);
        }
    
        return (
        <PayPalButtons
            onApprove={on_approve}
            createSubscription={(data, actions) => {
                return actions.subscription
                    .create({
                        plan_id: props.plan_id,
                    })
                    .then((orderId) => {
                        return orderId;
                    });
            }}
            style={{
                label: "subscribe",
            }}
        />);
    }


    return (
	    <>
            <ButtonWrapper type="subscription" />
        </>
	);
}