import { PayPalButtons } from "@paypal/react-paypal-js";
import { PAYPAL } from "website/utils/constants";

export default function PayPalPurchaseButton(props) {
    
    const create_order = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        currency_code: props.currency,
                        value: props.amount,
                    },
                },
            ],
        })
        .then((orderId) => {
            return orderId;
        });
    }

    const on_approve = (data, actions) => {
        return actions.order.capture().then(() => {
            props.setOpen(true);
        })
    }



    return (
	    <>
            <PayPalButtons 
                forceReRender={[PAYPAL.style]}
                disabled={false}
                amount={props.amount}
                createOrder={create_order}
                onApprove={on_approve}
            />
        </>
	);
}