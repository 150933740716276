import tw from "twin.macro";
import styled from "styled-components";
import {Container as ContainerBase } from "components/misc/Layouts.js"
import logo from "../images/FramedLogo.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import { ReactComponent as InstagramIcon } from "../images/icons/instagram.svg";
import { ReactComponent as TikTokIcon } from "../images/icons/tiktok.svg";

import { COLORS, URLS } from "website/utils/constants";


const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`

const link_style = {
  color: COLORS.secondary,
  textDecoration: "none"
}

export default () => {
  return (
    <Container style={{backgroundColor: COLORS.dark_primary}}>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} />
            <LogoText>liflofit</LogoText>
          </LogoContainer>
          <LinksContainer>
            <Link href={URLS.home} style={link_style}>Home</Link>
            <Link href={URLS.about} style={link_style}>About</Link>
            <Link href={URLS.pricing} style={link_style}>Pricing</Link>
            {/* <Link href={URLS.blog} style={link_style}>Blog</Link> */}
            <Link href={URLS.testimonials} style={link_style}>Testimonials</Link>
            {/* <Link href={URLS.contact} style={link_style}>Contact</Link> */}
            <Link href={URLS.terms_of_service} style={link_style}>Terms & Conditions</Link>
            <Link href={URLS.privacy_policy} style={link_style}>Privacy Policy</Link>
          </LinksContainer>
          <SocialLinksContainer>
            <SocialLink href="https://www.instagram.com/liflo_fit/" target="_blank">
              <InstagramIcon />
            </SocialLink>
            <SocialLink href="https://www.facebook.com/people/Liflofit/61558237247216/?rdid=ujp466bKHiQHjll7" target="_blank">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://www.tiktok.com/@liflofit8?_t=8npTafuHeTY&_r=1" target="_blank">
              <TikTokIcon />
            </SocialLink>
          </SocialLinksContainer>
          
          <CopyrightText style={{color: "white", marginBottom: 15, marginTop: 30}}>
            <a href="mailto:liflofit@gmail.com" style={{color: "white", textDecoration: "none"}}>liflofit@gmail.com</a>
          </CopyrightText>
          <CopyrightText style={{color: "white", marginTop: 15}}>
            &copy; Copyright {new Date().getFullYear()}, Liflofit All Rights Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
