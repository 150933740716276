import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "website/Home";
import About from "website/About";
import Contact from "website/Contact";
import TermsOfService from "website/TermsOfService";
import PrivacyPolicy from "website/PrivacyPolicy";
import { URLS } from "website/utils/constants";
import Testimonials from "website/Testimonials";
import Pricing from "website/Pricing";

export default function App() {

  return (
    <>
      <Router>
        <Routes>
          <Route path = "*" element = {<Home />} />
          <Route path = {URLS.home} element={<Home />} />
          <Route path = {URLS.about} element={<About />} />
          <Route path = {URLS.pricing} element={<Pricing />} />
          <Route path = {URLS.testimonials} element={<Testimonials />} />
          {/* <Route path = {URLS.contact} element={<Contact />} /> */}
          <Route path = {URLS.terms_of_service} element={<TermsOfService />} />
          <Route path = {URLS.privacy_policy} element={<PrivacyPolicy />} />
        </Routes>
      </Router>
    </>
  );
}