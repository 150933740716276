import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

import PersonalizedFitnessPlans from "../images/icons/PersonalizedFitnessPlans.svg"
import WeightLossSoultions from "../images/icons/WeightLossSoultions.svg"
import FunctionalMobilityTraining from "../images/icons/FunctionalMobilityTraining.svg"
import HealthAndLongevity from "../images/icons/HealthAndLongevity.svg"
import ConfidenceBuilding from "../images/icons/ConfidenceBuilding.svg"
import StrengthAndTone from "../images/icons/StrengthAndTone.svg"
import {ReactComponent as Specialization} from "../images/icons/Specializations.svg"
import {ReactComponent as Qualifications} from "../images/icons/Qualifications.svg"

const base_url = "https://nickninov.com/temp_pics"

export const IMAGES = {
  our_mission: `${base_url}/Mission.png`,
  our_vision: `${base_url}/Vision.png`,
  marriage: `${base_url}/Wedding.png`,
  fred: {
    pushup: `${base_url}/Fred Pushup.jpg`,
    profile: `${base_url}/Fred Profile.png`
  },
  keren: {
    split: `${base_url}/Keren Split.png`,
    profile: `${base_url}/Keren Profile.png`
  },
  faq: {
    general: `${base_url}/General FAQs.png`,
    home: `${base_url}/FromHome FAQs.png`,
    online: `${base_url}/Online FAQs.png`,
    outdoor: `${base_url}/Ourdoor FAQs.png`
  },
  home: `${base_url}/cover 2.jpeg`,
  area: `${base_url}/area.jpeg`
}


export const BIOS = {
    fred: [
        "Hello, I'm Frederico, a passionate Portuguese personal trainer dedicated to transforming lives through fitness.",
        "As a teenager, I struggled with anxiety and insecurity. However, at 18, I began my journey of self-improvement through fitness. Through dedication and self-teaching, I overcame my anxieties and transformed my body and mind.",
        "Fitness has been my constant companion for over 22 years, empowering me to become the best version of myself. My journey instilled a deep passion for helping others achieve their fitness goals. Whether shedding pounds, gaining strength, or sculpting a physique, I guide individuals on unique fitness journeys with a holistic, personalized approach.",
        "Ready to start your transformative fitness journey? Let's unlock your potential together and achieve your goals for a healthier, happier you."
    ],

    keren: [
        "Hey there, I'm Keren!",
        "My fitness adventure started in my teens when I was a bit on the heavier side and not very active. Determined to change, I immersed myself in rigorous training, shedding excess weight but stumbling into overtraining and poor nutrition. This led me from overweight to skinny, fuelling my determination to grasp the science of fitness. In my more than 11-year journey, I took control, delving into self-learning to create a balanced workout and nutritional approach. Now, I'm standing strong and healthy, excited to share the valuable lessons I've learned. With my friendly vibe, I'm here to guide you on a fitness journey that's effective and enjoyable.",
        "Ready to transform your life through an exciting fitness journey?",
        "I'll craft personalized programs that not only meet but exceed your goals! Join me in sculpting a healthier, happier you, using a rich blend of specializations and training methods.",
        "Let's light that spark and turn your goals into reality!"
    ],

    mission: [
        "Our mission is to tailor fitness to your needs, teaching you consistency while guiding you with our expertise. We adapt methods and share knowledge gained from years of learning to help you achieve efficient results, avoiding past mistakes. Ultimately, our goal is to train you to be your best self, aligned with your unique goals."
    ],

    vision: [
        "Our vision is to enrich lives through fitness, recognizing its pivotal role in overall well-being. Whether you're sedentary with posture issues or seeking vitality, we nurture your journey. With our unique functional mobility programmes, we empower you to stand taller, grow stronger, and embrace a joyful existence"
    ],

    both: [
        "We are a married couple that met in London. We're both passionate about fitness, and that's what initially connected us. Our shared dedication to helping others thrive led us to create this personal training service. We're here to share our knowledge and support you on your fitness journey, whether your goal is to get fit, lose weight, become healthier, stronger, faster, get ready for a wedding, or achieve that amazing beach body. Whatever your goals are, we're here to help you reach them."
    ],
    operate: [
      "For the home training and outdoor training we operate in East London, feel free to contact us for more details."
    ]
}



export const FEATURES = {
    fred: [
        {
          title: "CIMSPA certified Level 3 personal trainer",
          Icon: Qualifications
        },
        {
          title: "Level 2 Gym Instructor / First Aid",
          Icon: Qualifications
        },
        {
          title: "Strength Training",
          Icon: Specialization
        },
        {
          title: "Ballstic Training",
          Icon: Specialization
        },
        {
          title: "Muscle Building & Tone",
          Icon: Specialization
        },
        {
          title: "Weight Loss & Management",
          Icon: Specialization
        },
        {
          title: "Calisthenics",
          Icon: Specialization
        },
        {
          title: "Functional & Mobility Training",
          Icon: Specialization
        },
        {
          title: "Group Training",
          Icon: Specialization
        }
    ],

    keren: [
      {
        title: "CIMSPA certified Level 3 personal trainer",
        Icon: Qualifications
      },
      {
        title: "Level 2 Gym Instructor / First Aid",
        Icon: Qualifications
      },
      {
        title: "Functional & Mobility Training",
        Icon: Specialization
      },
      {
        title: "Weight Loss & Management",
        Icon: Specialization
      },
      {
        title: "Muscle Growth & Tone",
        Icon: Specialization
      },

      {
        title: "Flexibility Training",
        Icon: Specialization
      },
      {
        title: "Legs & Glutes Training",
        Icon: Specialization
      },
      {
        title: "Group Training",
        Icon: Specialization
      },

  ],

    overall: [
      {
        imageSrc: PersonalizedFitnessPlans,
        title: "Personalized Fitness Plans",
        description: "Tailored programs designed around your needs, preferences, and goals ensure optimal results and satisfaction."
      },
      { 
        imageSrc: WeightLossSoultions, 
        title: "Weight Loss Solutions",
        description: "Expert guidance and support for sustainable weight loss through targeted workouts and nutrition advice."
      },
      { 
        imageSrc: FunctionalMobilityTraining, 
        title: "Functional Mobility Training",
        description: "Improve strength, agility, and flexibility with functional movements and mobility exercises."
      },
      { 
        imageSrc: HealthAndLongevity, 
        title: "Health and Longevity",
        description: "Enhance overall wellness and longevity with a comprehensive approach to fitness and lifestyle habits."
      },
      { 
        imageSrc: ConfidenceBuilding, 
        title: "Confidence Building",
        description: "Transform your body and mindset, building resilience and confidence through consistent progress"
      },
      { 
        imageSrc: StrengthAndTone, 
        title: "Strength and Tone",
        description: "Achieve muscle growth, strength, and body tone with targeted exercises for visible results."
      }
    ]
}



export const TESTIMONIALS = [
    [
        {
          imageSrc:
            `${base_url}/testimonials/Nikolay Ninov.png`,
          quote:
            "I loved the outdoor training sessions with Frederico. I went through a progressive well built calisthenics programme that helped me lose weight, learn new techniques, and correct posture while training in a park next to my house. Amazing results, I will keep working with him.",
          customerName: "Nikolay Ninov"
        },
        {
          imageSrc:
          `${base_url}/testimonials/David Ramon.png`,
          quote:
            "I was struggling with back pain, low energy, and mood, but my health improved in the past 6 months with the online training LiFloFit programme. Worked on strength with Frederico and on my mobility and flexibility with Keren, both great and friendly teachers. I feel happier, and more productive in my work, and the back pain is gone.",
          customerName: "David Ramon"
        },
        {
          imageSrc:
          `${base_url}/testimonials/Sofya Kneafcy.png`,
          quote:
            "Keren is a fun, friendly, and enthusiastic personal trainer! I've been seeing her since January and she has helped me to feel confident in the gym, she is kind and encouraging and will always push you to do more than you think you can! She always keeps sessions varied and interesting, which helps keep me motivated for my solo sessions!",
          customerName: "Safaya Kneafcy"
        }
      ],
      [
        {
          imageSrc:
          `${base_url}/testimonials/Simone.png`,
          quote:
            "Frederico is an excellent professional, I am very happy with his personal training work. Highly recommend.",
          customerName: "Simone"
        },
        {
          imageSrc:
          `${base_url}/testimonials/Zhang Li.png`,
          quote:
            "Started training with Keren, and never trained before. She's amazing, very knowledgeable and fun. I've been learning a lot, my body quickly is looking better. Overall I feel much more confident in myself and training.",
          customerName: "Zhang Li"
        },
      ],
]


export const FAQs = {
  generic: [
    {
      question: "What is personal training",
      answer: "Personal training involves working one-on-one with a certified fitness professional to achieve specific fitness goals tailored to your individual needs and abilities."
    },
    {
      question: "What are the benefits of personal training?",
      answer: "Personal training offers several benefits, including personalized workout plans, accountability, motivation, proper form and technique guidance, and faster progress towards your fitness goals"
    },
    {
      question: "How do I know if personal training is right for me?",
      answer: "Personal training is suitable for individuals of all fitness levels, ages, and backgrounds. Whether you're new to exercise, recovering from an injury, or seeking to enhance your athletic performance, a personal trainer can customize a program to meet your needs."
    },
    {
      question: "What qualifications do our personal trainers have?",
      answer: "Our trainers are certified professionals with credentials from the reputable organization CIMSPA. They possess expertise in exercise science, anatomy, physiology, and nutrition to provide safe and effective training programs."
    },
    {
      question: "What can I expect during a personal training session?",
      answer: "Each personal training session is tailored to your specific goals and fitness level. Your trainer will lead you through a series of exercises, providing instruction, feedback, and motivation to ensure proper form and technique."
    },
    {
      question: "How often should I train with a personal trainer?",
      answer: "The frequency of personal training sessions depends on your goals, schedule, and budget. Most clients benefit from training sessions 2-3 times per week to maintain consistency and see optimal results."
    },
    {
      question: "Can personal training help me lose weight/build muscle/improve my athletic performance?",
      answer: "Yes, personal training can help you achieve a variety of fitness goals, including weight loss, muscle building, improved endurance, flexibility, and sports-specific training."
    },
    {
      question: "What types of exercises and equipment do you use in personal training sessions?",
      answer: "Our personal trainers utilize a variety of exercises and equipment tailored to your goals and preferences. This may include bodyweight exercises, free weights, resistance bands, and functional training equipment."
    },
    {
      question: "Do you offer nutrition guidance as part of personal training?",
      answer: " Our personal trainers can provide excellent nutrition and meal planning advice to complement your fitness program and help you achieve your desired results. They won’t create meal plans or complete nutrition programs, for that matter seek a nutritionist."
    },
    {
      question: "How do I get started with personal training?",
      answer: "To get started, simply contact us to schedule a complimentary consultation. During the consultation, we'll discuss your goals, assess your fitness level, and recommend a personalized training plan tailored to your needs and preferences."
    },
  ],

  online: [
    {
      question: "How does online personal training work?",
      answer: "Online personal training allows you to work with our certified fitness professionals remotely via video calls, customized workout plans, and virtual coaching sessions. Communication and support are typically facilitated through email, messaging apps, or online platforms."
    },
    {
      question: "What equipment do I need for online personal training?",
      answer: "The equipment needed for online personal training depends on your fitness goals and the exercises prescribed by your trainer. Common equipment may include dumbbells, resistance bands, stability balls, and access to a reliable internet connection and video conferencing software."
    },
    {
      question: "Can I still receive personalized guidance and feedback with online personal training?",
      answer: "Yes, online personal training provides personalized guidance and feedback tailored to your goals, fitness level, and progress. Your trainer will review your form, technique, and progress through video demonstrations and virtual coaching sessions."
    }
  ],

  outdoors_group: [
    {
      question: "What is outdoor group training?",
      answer: "Outdoor group training involves exercising in a group setting outdoors, led by a certified fitness instructor. Group sessions may include a variety of exercises such as cardio, strength training, HIIT, and bodyweight exercises, utilizing outdoor spaces like parks or trails."
    },
    {
      question: "What are the benefits of outdoor group training?",
      answer: "Outdoor group training offers several benefits, including exposure to fresh air and natural surroundings, social interaction and support from group members, increased motivation and accountability, and the opportunity to enjoy varied and dynamic workouts in a scenic environment."
    },
    {
      question: "What should I bring to an outdoor group training session?",
      answer: "Participants should wear comfortable athletic clothing and supportive footwear suitable for outdoor exercise. It's also recommended to bring a water bottle, towel, a mat, sunscreen, and any personal equipment specified by the instructor. "
    },
    {
      question: "Where will the outdoor training sessions take place?",
      answer: "Our outdoor training sessions will be held in select parks located in East London, for which we have obtained permission to conduct our fitness sessions. These parks offer spacious and scenic environments conducive to outdoor exercise and are conveniently located for our clients' accessibility and enjoyment."
    },
  ],
  outdoors_one_to_one: [
    {
      question: "What is outdoor one-on-one training?",
      answer: "Outdoors one-on-one training involves working with a certified personal trainer in a private outdoor setting, such as a park. Sessions are tailored to your individual goals, preferences, and fitness level, providing personalized attention and support from the trainer."
    },
    {
      question: "How does outdoor one-on-one training differ from indoor personal training?",
      answer: "Outdoors one-on-one training involves working with a certified personal trainer in a private outdoor setting, such as a park. Sessions are tailored to your individual goals, preferences, and fitness level, providing personalized attention and support from the trainer."
    },
    {
      question: "What are the advantages of outdoor one-on-one training?",
      answer: "Outdoors one-on-one training provides a personalized and focused workout experience with the added benefits of outdoor exercise, including improved mood, reduced stress, and enhanced mental well-being. It offers a refreshing alternative to indoor gym settings and allows for greater flexibility and creativity in exercise programming."
    },
    {
      question: "Where will the outdoor training sessions take place?",
      answer: "Our outdoor training sessions will be held in a selected park near you, located in East London, for which we have obtained permission to conduct our fitness sessions. These parks offer spacious and scenic environments conducive to outdoor exercise and are conveniently located for our clients' accessibility and enjoyment."
    }
  ],

  home: [
    {
      question: "What is home personal training?",
      answer: "Home personal training involves working with a certified fitness professional who travels to your home to provide personalized workout sessions in the comfort and convenience of your own space."
    },
    {
      question: "What are the benefits of home personal training?",
      answer: "Home personal training offers several benefits, including convenience, privacy, and flexibility in scheduling. It eliminates the need to travel to a gym and allows you to work out in a familiar environment without the distractions or crowds of a public fitness facility."
    },
    {
      question: "What equipment do I need for home personal training sessions?",
      answer: "The equipment needed for home personal training depends on your fitness goals and the exercises prescribed by your trainer. Common equipment may include dumbbells, resistance bands, stability balls, and exercise mats. Your trainer may bring additional equipment as needed."
    },
    {
      question: "Do I need a lot of space for home personal training sessions?",
      answer: "No, you don't need a lot of space for home personal training sessions. Your trainer will work with the available space in your home to design workouts that are suitable for your environment. Whether you have a dedicated home gym or a small living room, your trainer will customize the workouts to fit your space."
    },
    {
      question: "How do I get started with home personal training?",
      answer: "To get started with home personal training, simply contact us to schedule a consultation. During the consultation, we'll discuss your fitness goals, assess your home environment, and recommend a personalized training plan tailored to your needs and preferences."
    },
    {
      question: "Is home personal training suitable for beginners?",
      answer: "Yes, home personal training is suitable for individuals of all fitness levels, including beginners. Your trainer will tailor the workouts to your current fitness level and gradually progress as you improve. Whether you're new to exercise or have experience, home personal training provides a supportive and individualized approach to reaching your goals."
    }
  ]
}


export const PLANS = {
  online: [
    {
      price: "£159.00",
      duration: "month",
      name: "Silver Package",
      main_feature: "1 month",
      features: [
        "8 personalised workout programs per month", 
        "Access to an exercise video library", 
        "Weekly progress check-ins"
      ],
      plan_id: "P-6TD3587393705241FMYJPJLQ"
    },
    {
      price: "£279.99",
      duration: "month",
      name: "Gold Package",
      main_feature: "3 months",
      features: [
        "4 live sessions per month", 
        "4 personalised workout programs per month", 
        "Access to exercise demos and instructional videos",
        "Unlimited messaging support",
      ],
      plan_id: "P-2Y529777Y1409964EM2AXQLI"
    },
    {
      price: "£329.99",
      duration: "month",
      name: "Elite Package",
      main_feature: "6 months",
      features: [
        "8 live sessions per month",
        "4 personalised workout programs per month",
        "Access to an exercise video library",
        "Weekly nutrition consultations",
        "Unlimited messaging support",
        "Monthly progress reports"
      ],
      plan_id: "P-7WW25346WR2681524M2AXRGI"
    },
  ],
  outdoors: [
    {
      price: "£39.99",
      duration: "session",
      name: "Solo Summit Package",
      main_feature: "",
      features: [
        "Single person",
        "One-hour outdoor training session",, 
        "Perfect for individuals seeking personalized attention and a tailored workout experience",
      ],
      amount: "39.99"
    },
    {
      price: "£59.99",
      duration: "session",
      name: "Group Thrive Package",
      main_feature: "",
      features: [
        "Up to 5 people", 
        "One-hour outdoor training session",
        "Ideal for friends or small groups looking for a fun and motivating workout together."
      ],
      amount: "59.99"
    },
    {
      price: "£99.99",
      duration: "session",
      name: "Team Momentum Package",
      main_feature: "",
      features: [
        "Up to 10 people", 
        "One-hour outdoor training session",
        "Great for larger groups or corporate teams aiming to boost morale and fitness levels collectively."
      ],
      amount: "99.99"
    },
  ],
  home: [
    {
      price: "£199.99",
      duration: "month",
      name: "Silver Package",
      main_feature: "Affordable entry-level package for those starting their fitness journey",
      features: [
        "4 sessions per month",
        "Personalised training plans",
        "Certified trainer travel to your location",
        "One-on-one attention",
        "Flexible scheduling"
      ],
      plan_id: "P-5MP61480H4860594FM2AXSHY"
    },
    {
      price: "£359.99",
      duration: "session",
      name: "Gold Package",
      main_feature: "Great value with additional support and nutritional advice",
      features: [
        "8 sessions per month",
        "Personalised training plans",
        "Certified trainer travel to your location",
        "One-on-one attention",
        "Flexible scheduling",
        "Nutritional guidance"
      ],
      plan_id: "P-24V24404LL2254230M2AXTYY"
    },
    {
      price: "£479.99",
      duration: "session",
      name: "Elite Package",
      main_feature: "Comprehensive plan for those serious about their fitness goals",
      features: [
        "12 sessions per month",
        "Personalised training plans",
        "Certified trainer travel to your location",
        "One-on-one attention",
        "Flexible scheduling",
        "Nutritional guidance and meal planning",
        "Priority scheduling"
      ],
      plan_id: "P-74L61857K2225382XM2AXUSA"
    },
  ]
}
