import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import {ReactComponent as SvgDotPattern } from "images/dot-pattern.svg"
import { COLORS } from "website/utils/constants";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);


const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-center rounded-full p-2 flex-shrink-0`}
  svg {
    ${tw`w-5 h-5 text-darker_theme_primary`}
  }
`;

const FeatureText = tw.div`mt-4 md:mt-0 md:ml-4 text-center md:text-left`;
const FeatureHeading = tw.div`font-bold text-lg text-darker_theme_primary`;
const FeatureDescription = tw.div`mt-1 text-sm`;

const Features = tw.div`mt-8 max-w-sm mx-auto md:mx-0`;
const Feature = tw.div`mt-8 flex items-start flex-col md:flex-row`;

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = styled(SvgDotPattern)(props => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
])

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);


export default ({
  subheading = "Our Expertise",
  heading = (
    <>
      Designed & Developed by <span tw="text-primary-500">Professionals.</span>
    </>
  ),
  description = [],
  primaryButtonText = "Learn More",
  primaryButtonUrl = "https://timerse.com",
  imageSrc = TeamIllustrationSrc,
  buttonRounded = true,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  textOnLeft = true,
  showButton = true,
  features = [],
  borderRadius = "0%",
  borderColor = null
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const button_style = {
    backgroundColor: COLORS.dark_primary, 
    color: COLORS.secondary,
    textDecoration: "none"
  }

  const button = (show) => {
    if(!show) return;

    return <PrimaryButton buttonRounded={buttonRounded} as="a" href={primaryButtonUrl} style={button_style}>
            {primaryButtonText}
          </PrimaryButton>
  }

  const card_icon = (feature) => {
    if (!feature.Icon) return
    return <FeatureIconContainer style={{paddingTop: 0.2}}>{<feature.Icon />}</FeatureIconContainer>
  }

  const card_features = (features) => {
    if(features.length === 0) return;

    return <Features>
            {features.map((feature, index) => (
              <Feature key={index} style={{display: "inline-flex", marginTop: 15}}>
                                
                {card_icon(feature)}
                <FeatureText>
                  <FeatureHeading>{feature.title}</FeatureHeading>
                </FeatureText>
              </Feature>
            ))}
          </Features>
  }

  const img_style = {
    width: "100%",
    borderRadius: borderRadius,
    border: (borderColor !== null) ? `2px solid ${borderColor}` : null
  }

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image css={img_style} src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded}/>

          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading style={{color: COLORS.dark_primary}}>{heading}</Heading>
            
            {
              description.map((current) => {
                return <Description style={{textAlign: "justify"}}>{current}</Description>
              })
            }

            {button(showButton)}
            
            {card_features(features)}

          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};