import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useState } from 'react';
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "../images/ContactUs.svg";
import { COLORS, EMAIL_URL } from "website/utils/constants";
import "../utils/style.css"

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-darker_theme_primary`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
  subheading = "Contact Us",
  heading = <>Feel free to <span tw="text-darker_theme_primary">get in touch</span><wbr/> with us.</>,
  description = "",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [email_error, setEmailError] = useState("");
  const [name_error, setNameError] = useState("");
  const [subject_error, setSubjectError] = useState("");
  const [message_error, setMessageError] = useState("");

  const [sent_message, setSentMessage] = useState("");
  const [sent_color, setSentColor] = useState("");

  function clearEmailStates(){
    setEmail("");
    setName("");
    setSubject("");
    setMessage("");

    setEmailError("");
    setNameError("");
    setSubjectError("");
    setMessageError("");
  }

  const button_style = {
    backgroundColor: COLORS.dark_primary,
    border: "none"
  }

  const input_style = {
    border: `1px solid ${COLORS.dark_primary}`
  }

  const validate_email = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const send_data = async (event) => {
    event.preventDefault();
    setSentMessage("");
    setSentColor("");
    let errors = 0;
    // Catch errors
    if(email.length === 0 || !validate_email(email)){
      setEmailError("Please enter a valid email...");
      errors++;
    }
    else {
      setEmailError("");
    }

    if(message.length === 0){
      setMessageError("Please enter a message...");
      errors++;
    }
    else {
      setMessageError("");
    }

    if(name.length === 0){
      setNameError("Please enter your name...");
      errors++;
    }
    else {
      setNameError("");
    }
    
    if(subject.length === 0){
     setSubjectError("Please enter a subject...");
     errors++;
    }
    else {
      setSubjectError("");
    }
    
    if(errors > 0){
      return;
    }
    
    let body = {
      address: email,
      full_name: name,
      subject: subject,
      message: message,
    }

    const options = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }

    let response = await fetch(EMAIL_URL, options);

    if (response.status === 200){
      setSentMessage("Message has been sent! We will get in contact with you within 24 hours");
      setSentColor("green");
    }
    else {
      setSentMessage(response.statusText);
      setSentColor("red")
    }

    clearEmailStates();
  }

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            
            {subheading && <Subheading style={{color: COLORS.dark_primary}}>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            
            {description && <Description style={{color: COLORS.text}}>{description}</Description>}
            <Form onSubmit={send_data}>
              
              <Subheading className="error error_padding">{email_error}</Subheading>
              <Input type="email" name="email" placeholder="Your Email Address" value={email} style={input_style} onChange={(e) => {
                setEmail(e.target.value);
                
                var message = "Not a valid email...";

                if(e.target.value === "" || validate_email(e.target.value)){
                  message = ""
                }

                setEmailError(message);
              }}/>

              <Subheading className="error error_padding">{name_error}</Subheading>
              <Input type="text" name="name" placeholder="Full Name" value={name} style={input_style} onChange={(e) => setName(e.target.value)} />

              <Subheading className="error error_padding">{subject_error}</Subheading>
              <Input type="text" name="subject" placeholder="Subject" value={subject} style={input_style} onChange={(e) => setSubject(e.target.value)} />

              <Subheading className="error error_padding">{message_error}</Subheading>
              <Textarea name="message" placeholder="Your Message Here" value={message} style={input_style} onChange={(e) => setMessage(e.target.value)}/>

              <SubmitButton type="submit" style={button_style}>{submitButtonText}</SubmitButton>
              <Subheading className="sent_message" style={{color: sent_color}}>{sent_message}</Subheading>
              
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
