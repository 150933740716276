import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import InstructorCard from "./components/InstructorCard";
import Navigation from "./components/Navigation";
import Video from "./components/Video";
import Features from "./components/Features";
import { COLORS, URLS } from "./utils/constants";
import Footer from "./components/Footer";
import ReviewPreview from "./components/Reviews";
import Pricing from "./components/Pricing";
import { BIOS, FEATURES, PLANS, TESTIMONIALS, IMAGES } from "./utils/text";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

const HighlightedText = tw.span`bg-darker_theme_primary text-gray-100 px-4 transform -skew-x-12 inline-block`;

export default () => (
  <AnimationRevealPage disabled>
    <Navigation />
    <Video />

    <InstructorCard
        subheading={<Subheading style={{color: COLORS.text}}>Personal Trainer</Subheading>}
        heading="Frederico Serra"
        description={BIOS.fred.slice(0, 2)}
        buttonRounded={false}
        primaryButtonText="Specializations"
        primaryButtonUrl={URLS.about}
        imageSrc= {IMAGES.fred.profile}
        borderRadius="10%"
        borderColor={COLORS.dark_primary}
      />

    <InstructorCard
        subheading={<Subheading style={{color: COLORS.text}}>Personal Trainer</Subheading>}
        heading="Keren Zhu"
        description={BIOS.keren.slice(0, 2)}
        buttonRounded={false}
        primaryButtonText="Specializations"
        primaryButtonUrl={URLS.about}
        imageSrc = {IMAGES.keren.profile}
        textOnLeft={false}
        borderRadius="10%"
        borderColor={COLORS.dark_primary}
    />
    <Features features={FEATURES.overall} />

    <Pricing plans={PLANS}/>

    <ReviewPreview 
      heading={<>Customers <HighlightedText>Love Us.</HighlightedText></>}
      testimonials={[TESTIMONIALS[0]]}
    />
    
    <Footer />
  </AnimationRevealPage>
);
