import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import { SectionHeading } from "components/misc/Headings";
import { TOS_DATE, URLS } from "./utils/constants";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Privacy Policy" }) => {
  return (
    <AnimationRevealPage disabled>
      <Container>
      <Navigation />
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last updated: {TOS_DATE}</p>

            <h2>
              Introduction
            </h2>

            <p>
            Liflo Fit, Inc. ("Liflo Fit," "Company," "we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website, www.liflofit.com (the "Site"), and use our Services.
            </p>

            <h2>
              Information We Collect
            </h2>

            <p>
              We may collect information about you in a variety of ways. The information we may collect on the Site includes:

              <ul>
                <li>
                  <strong>Personal Data</strong>: Personally identifiable information, such as your name, shipping address, email address, and telephone number, and demographic information, such as your age, gender, hometown, and interests.
                </li>

                <li>
                  <strong>Health Information</strong>: Information about your health and fitness, including your responses to the PAR-Q and any other health assessments.
                </li>

                <li>
                  <strong>Payment Information</strong>: Data necessary to process your payments if you make purchases, such as your payment instrument number (e.g., a credit card number), and the security code associated with your payment instrument.
                </li>

              </ul>
            </p>

            <h2>
              Use of Your Information
            </h2>

            <p>
              Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to:

              <ul>
                <li>
                  Create and manage your Account
                </li>

                <li>
                  Process your transactions and send you related information, including purchase confirmations and invoices
                </li>

                <li>
                  Manage and provide the Services
                </li>

                <li>
                  Improve the quality, safety, and performance of our Services
                </li>

                <li>
                  Send you marketing and promotional communications
                </li>

                <li>
                  Enforce our terms, conditions, and policies
                </li>

              </ul>
            </p>

            <h2>
              Disclosure of Your Information
            </h2>

            <p>
              We may share information we have collected about you in certain situations. Your information may be disclosed as follows:

              <ul>
                <li>
                  <strong>By Law or to Protect Rights</strong>: If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.
                </li>

                <li>
                  <strong>Third-Party Service Providers</strong>: We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.
                </li>
              </ul>
            </p>

            <h2>
              Security of Your Information
            </h2>

            <p>
              We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other types of misuse.
            </p>
            
            <h2>
              Policy for Children
            </h2>

            <p>
              We do not knowingly solicit information from or market to children under the age of 16. If we learn we have collected personal information from a child under age 16 without verification of parental consent, we will delete that information as quickly as possible.
            </p>

            <h2>
              Changes to This Privacy Policy
            </h2>

            <p>
              We may update this Privacy Policy from time to time to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons. Any changes will be effective immediately upon posting on the Site. Your continued use of the Site and Services following the posting of any changes constitutes acceptance of those changes.
            </p>

            <h2>
              Contact Us
            </h2>

            <p>
              If you have any questions or concerns about this Privacy Policy, please <a href={URLS.contact}>contact us</a>.
            </p>

          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
